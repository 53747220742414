import nastup1 from "../images/muzika/IMG_3847.jpg";
import nastup2 from "../images/muzika/IMG_3846.jpg";
import nastup3 from "../images/muzika/IMG_3848.jpg";

export const MusicEventsContent = [
  {
    image: nastup1,
    date: "Petak 2.2.",
    title: "Free cats band",
    description: "Ovog petka uživajte u ritmu koji traje celu noć! Free cats band vas vodi na uzbudljivo muzičko putovanje kroz različite žanrove džeza. Vidimo se 2.2. na adresi Žorža Klemansoa 19.",
  },
  {
    image: nastup2,
    date: "Subota, 10.2.",
    title: "DJ Iron",
    description: "Subota je dan za mnogo dobar provod! Ukoliko ste propustili novogodišnju matine zabavu i opasno dobar zvuk DJ Iron-a, ne mora više da slušate utiske- uverite se i sami! Čekamo vas 10.2. da zajedno gruvamo uz vrhunski ritam i najbolje ploče. ",
  },
  {
    image: nastup3,
    date: "Petak, 16.2.",
    title: "Free cats band",
    description: "Kod domaćina pravog džez zvuka, Free cats band ponovo dolazi u goste! Barrel House vam nudi idealnu priliku da pokažete svoje plesne korake uz vaše omiljene hitove. Obezbedite vaše mesto u petak 16.2. pozivom na večeri dobrog ritma. ",
  },
]

