import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import { SEO } from "../components/seo"
import { MusicEventsContent } from "../components/MusicEventsContent"

const MuzikaPage = () => {
  return (
    <Layout
      backArrow={true}
      bodyClasses={" flat-menu-page -white-third-bg meny-right "}
    >
      <Breadcrumbs currentPage="Muzika" />
      <main className="main-content center-aligned">
        <div className="container">
          <h1 className="title-of-page">Muzički program</h1>

          <section className="company-info-section -bottom-indent">
            <div className="row -bottom-indent">
              <div className="col-12 col-lg-12"> </div>
            </div>
            <div className="row">
              <div className="col-12 about__company-content">
                <h2 className="title-section title-accent title-section-music">Aktuelni muzički program</h2>
                <div className="text-block -no-margin">

                  { MusicEventsContent.map(event => (
                    <div className="music-event-wrapper">
                      {event.image ? <img src={event.image} alt={event.description} /> : <img src="" />}
                      <div className="music-event-info">
                        <h4 className="music-event-date">{event.date}</h4>
                        <h2 className="music-event-title">{event.title}</h2>
                        <p className="music-event-description">{event.description}</p>
                      </div>
                    </div>
                  ))}

                  <a className="music-call-button" href="tel:00381604466039">060/44-66-039</a>
                  <p style={{marginTop: '1rem'}}>Pozivom na ovaj broj ili porukom u inbox-u očekuje vas veče sjajne muzike i odlične gastronomske ponude!</p>
                </div>
              </div>
            </div>
          </section>

        </div>
      </main>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export default MuzikaPage
